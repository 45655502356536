import React, { useEffect, useRef, useState } from 'react'
import { ModalComments } from './ModalComments';
import { ColorSelector } from './ColorSelector';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import ModalAlarm from '../ModalAlarm';
import { toast } from 'react-toastify';

export const Chart = ({ mobile, serie1, serie2, serieComments, dato1, dato2, navigator, comments, nuevoDato1, nuevoDato2, tooltipEnabled,
    pos, setModalDownVar, setPosInferior, setShowModalVarDown, deleteVarMatriz, showDataLeft,
    colorSerie1, setColorSerie1, colorSerie2, setColorSerie2, sinColor, rig, ejeAutomatico,
    funcionActualizarVarsDown, usaDefault,
    user, uid,
    horizontal,
    showComments,
    colors
}) => {

    const chartComponent = useRef(null);
    const [chart, setChart] = useState(null)
    const [xComentario, setXComentario] = useState(0)
    const [showModalComments, setShowModalComments] = useState(false);
    const handleCloseModalComments = () => setShowModalComments(false);

    const [isUserInteracting, setIsUserInteracting] = useState(false);

    const [varAlarm, setVarAlarm] = useState(0)
    const [varAlarmIdn, setVarAlarmIdn] = useState('')
    const [alarmMin, setAlarmMin] = useState(0)
    const [alarmMax, setAlarmMax] = useState(0)
    const [alarmExist, setAlarmExist] = useState(0)
    const [alarmId, setAlarmId] = useState(0)

    const [showModalAlarm, setShowModalAlarm] = useState(false);
    const handleCloseModalAlarm = () => setShowModalAlarm(false);

    const [min1, setMin1] = useState(0)
    const [max1, setMax1] = useState(0)
    const [min2, setMin2] = useState(0)
    const [max2, setMax2] = useState(0)

    //-----------------------------------------------------

    const syncCharts = (min, max) => {
        if (!navigator) return;
        const charts = Highcharts.charts.filter(chart => chart !== undefined);
        charts.forEach(c => {
            if (c) c.xAxis[0]?.setExtremes(min, max, true, false);
        });
    };

    const [options] = useState({
        chart: {
            height: Math.round(window.innerHeight - (mobile ? horizontal ? 150 : 300 : 252)),
            type: 'line',
            zoomType: 'x',
            /* panning: true, panKey: 'shift', */
            backgroundColor: colors?.grColorBg || '#ffffff',
            inverted: true,
            reflow: true,
            animation: { duration: 1000 },
            events: { mouseOut: function () { this.tooltip.hideCrosshairs() } },
        },
        xAxis: {
            type: 'datetime',
            minRange: 1,
            visible: !navigator,
            labels: {
                format: mobile && horizontal ? '{value:%H}' : '{value:%H:%M}',
                style: { fontSize: horizontal ? '6px' : '8px' }
            },
            events: {
                afterSetExtremes: function (e) {
                    syncCharts(e.min, e.max);
                    if (e.trigger === 'navigator') {
                        setIsUserInteracting(true);
                        if (e.max >= this.dataMax && e.min <= this.dataMin) {
                            setIsUserInteracting(false);
                        }
                    }
                }
            },
        },
        yAxis: [
            {
                opposite: false,
                color: colorSerie1,
                lineColor: colorSerie1,
                tickColor: colorSerie1,
                lineWidth: 1,
                min: parseInt(dato1?.nb_tldvrb_min) || 0,
                max: parseInt(dato1?.nb_tldvrb_max) || null,
            },
            {
                opposite: false,
                color: colorSerie2,
                lineColor: colorSerie2,
                tickColor: colorSerie2,
                lineWidth: 1,
                min: parseInt(dato2?.nb_tldvrb_min) || 0,
                max: parseInt(dato2?.nb_tldvrb_max) || null,
            }
        ],
        rangeSelector: { enabled: false },
        tooltip: {
            shared: true,
            enabled: tooltipEnabled,
            hideDelay: 1000,
            crosshairs: { snap: false },
            formatter: function () {
                const fontSize = mobile ? '10px' : '12px';
                let tooltipContent = `<div style="font-size: ${fontSize};"><b>${Highcharts.dateFormat('%d-%m-%Y', this.x)}</b><br/>`;
                /* let tooltipContent = `<b>${Highcharts.dateFormat('%A, %b %e, %Y', this.x)}</b><br/>`; */
                this.points.forEach((point) => {
                    //const seriesName = point.series.name === '0' ? dato1?.vr_var_nom : dato2?.vr_var_nom;
                    tooltipContent += `<span style="color:${point.color}">\u25CF</span> <b>${point.y.toFixed(2)}</b><br/>`;
                    /* tooltipContent += `<span style="color:${point.color}">\u25CF</span> ${seriesName}: <b>${point.y.toFixed(2)}</b><br/>`; */
                });
                tooltipContent += `</div>`;
                return tooltipContent;
            }
        },
        plotOptions: { series: { turboThreshold: 10000000, } },
        navigator: {
            enabled: navigator || (mobile && !horizontal),
            //height: navigator ? 90 : 0,
            series: {
                type: 'line',
                data: [],
                xAxis: 0,
                yAxis: 0
            },
            xAxis: { type: 'datetime' },
        },
        scrollbar: { enabled: false/* navigator && !mobile */ },
        legend: {
            //enabled: true,
            labelFormatter: function () {
                return this.options.legendName;
            },
        },
        series: [
            {
                name: '0',
                data: serie1,
                color: colorSerie1,
                yAxis: 0,
                legendIndex: 0,
                legendName: dato1?.vr_var_nom,
                events: {
                    click: function (event) {
                        const point = event.point;
                        const x = point.x;
                        const y = point.y;
                        const serieId = point.series.options.id;
                        chartClickComments(x, y, serieId);
                    }
                }
            },
            {
                name: '1',
                data: serie2,
                color: colorSerie2,
                yAxis: 1,
                legendIndex: 1,
                legendName: dato2?.vr_var_nom,
                events: {
                    click: function (event) {
                        const point = event.point;
                        const x = point.x;
                        const y = point.y;
                        const serieId = point.series.options.id;
                        chartClickComments(x, y, serieId);
                    }
                }
            }
        ],
        noData: {
            useHTML: true,
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030'
            }
        },
    })

    const optionsFlags = {
        chart: {
            height: window.innerHeight - (mobile ? 200 : 252),
            type: 'line',
            inverted: true,
            zoomType: 'x',
            reflow: true,
            backgroundColor: colors?.grColorBg || '#ffffff',
            events: { mouseOut: function () { this.tooltip.hideCrosshairs() } },
        },
        xAxis: {
            visible: mobile,
            type: 'datetime',
        },
        yAxis: [
            {
                min: 0,
                max: 10,
                color: colorSerie1,
                visible: false
            }
        ],
        rangeSelector: { enabled: false },
        scrollbar: { enabled: false },
        plotOptions: {
            series: { animation: false }
        },
        navigator: {
            enabled: navigator || mobile,
            height: navigator ? 90 : 0,
            series: {
                type: 'line',
                data: [],
                xAxis: 0,
                yAxis: 0
            },
            xAxis: {
                type: 'datetime'
            },
        },
        series: [
            {
                type: 'scatter',
                data: serieComments,
                marker: {
                    symbol: 'diamond',
                    fillColor: '#FF0000',
                    lineWidth: 4,
                    lineColor: '#FFFFFF'
                },
                dataLabels: {
                    enabled: true,
                    format: '{point.user}',
                    align: 'right',
                    style: {
                        color: 'black',
                        textOutline: 'none'
                    }
                },
            }],
        tooltip: {
            useHTML: true,
            outside: true,
            formatter: function () {
                return (
                    '<div style="min-width: 170px; word-wrap: break-word;"><b>' + this.point.user + '</b><br>' +
                    'Comentario: ' + this.point.comment + '<br>' 
                    /* 'Fecha: ' + new Date(this.point.dateInsert) + '</div>' */
                );
            },
            style: { width: '170px' },
            positioner: function (labelWidth, labelHeight, point) {
                return {
                    x: point.plotX - (mobile ? 0 : labelWidth - 10),
                    y: point.plotY
                };
            },
        }
    }

    //-----------------------------------------------------

    useEffect(() => {
        if (!chart) return;
        chart.update({ chart: { backgroundColor: colors?.grColorBg || '#ffffff' } });
    }, [colors])

    useEffect(() => {
        const preventDefault = (e) => e.preventDefault();
        document.addEventListener('touchmove', preventDefault, { passive: false });
        return () => { document.removeEventListener('touchmove', preventDefault) };
    }, []);

    useEffect(() => {
        if (chartComponent.current) setChart(chartComponent.current.chart);
    }, [chartComponent]);

    useEffect(() => {
        if (!chart || comments) return
        if (serie1 && serie1[0]) {
            chart?.series[0]?.setData(serie1, false);
            chart.hideLoading()
        } else {
            chart?.series[0]?.setData([], false);
            chart.hideLoading()
        }
        chart?.redraw();
        if (navigator) {
            const seriesMin = chart?.series[0]?.xData[0];
            const seriesMax = chart?.series[0]?.xData[chart.series[0].xData.length - 1];
            chart?.xAxis[1]?.setExtremes(seriesMin, seriesMax);
        }
    }, [serie1])

    useEffect(() => {
        if (!chart || comments) return
        if (serie2 && serie2[0]) {
            chart?.series[1]?.setData(serie2, false);
            chart.hideLoading()
        } else {
            chart?.series[1]?.setData([], false);
            chart.hideLoading()
        }
        chart?.redraw();
    }, [serie2])

    useEffect(() => {
        if (!chart) return
        if (serieComments && serieComments[0]) {
            chart?.series[0]?.setData(serieComments, false);
            chart.hideLoading()
        } else {
            chart?.series[0]?.setData([], false);
            chart.hideLoading()
        }
        chart?.redraw();
    }, [serieComments])

    useEffect(() => {
        if (!chart || comments) return;
        updateSeriesAndAxis(0, colorSerie1);
        chart.redraw();
    }, [colorSerie1])

    useEffect(() => {
        if (!chart || comments) return;
        updateSeriesAndAxis(1, colorSerie2);
        chart.redraw();
    }, [colorSerie2])

    useEffect(() => {
        if (!chart || comments) return
        chart.update({
            tooltip: {
                formatter: function () {
                    let tooltipContent = `<b>${Highcharts.dateFormat('%A, %b %e, %Y %H:%M', this.x)}</b><br/>`;
                    this.points.forEach((point) => {
                        const seriesName = point.series.name === '0' ? dato1.vr_var_nom : dato2.vr_var_nom;
                        tooltipContent += `<span style="color:${point.color}">\u25CF</span> ${seriesName}: <b>${parseFloat(point.y).toFixed(2)}</b><br/>`;
                    });
                    return tooltipContent;
                },
            }
        });
        actualizarEje(ejeAutomatico, dato1?.nb_tldvrb_min, dato1?.nb_tldvrb_max, dato2?.nb_tldvrb_min, dato2?.nb_tldvrb_max)
    }, [dato1, dato2]);

    useEffect(() => {
        if (!chart || comments) return
        chart?.series[0].update({ id: dato1?.nb_var_idn });
        chart?.series[0].update({ legendName: dato1?.vr_var_nom, });
    }, [dato1])

    useEffect(() => {
        if (!chart || comments) return
        chart?.series[1].update({ id: dato2?.nb_var_idn });
        chart?.series[1].update({ legendName: dato2?.vr_var_nom, });
    }, [dato2])

    useEffect(() => { addNewPointToChart(nuevoDato1, 0); }, [nuevoDato1])
    useEffect(() => { addNewPointToChart(nuevoDato2, 1); }, [nuevoDato2])

    useEffect(() => {
        if (!chart || comments) return;
        chart.update({
            tooltip: {
                shared: true,
                enabled: tooltipEnabled,
                hideDelay: 1000,
                crosshairs: { snap: false },
                formatter: function () {
                    const fontSize = mobile ? '8px' : '12px'; // Ajusta el tamaño de la letra según el valor de mobile
                    let tooltipContent = `<div style="font-size: ${fontSize};"><b>${Highcharts.dateFormat('%d-%m-%Y', this.x)}</b><br/>`;
                    this.points.forEach((point) => {
                        tooltipContent += `<span style="color:${point.color}">\u25CF</span> <b>${point.y.toFixed(2)}</b><br/>`;
                    });
                    tooltipContent += `</div>`;
                    return tooltipContent;
                }
            }
        });
        chart.redraw();
    }, [tooltipEnabled])


    useEffect(() => {
        if (!chart) return
        actualizarEje(ejeAutomatico, dato1?.nb_tldvrb_min, dato1?.nb_tldvrb_max, dato2?.nb_tldvrb_min, dato2?.nb_tldvrb_max)
    }, [ejeAutomatico])

    //------------------------------------------------------

    const addNewPointToChart = (nuevoDato, serieIndex) => {
        if (!chart || !nuevoDato || Object.keys(nuevoDato).length === 0) return;
        const fecha = new Date(nuevoDato.dt_mda_fch).getTime();
        const dato = parseInt(nuevoDato.nb_mda_val, 10);
        const prof = parseInt(nuevoDato.nb_mda_prof, 10);
        if (!isNaN(fecha) && !isNaN(dato) && !isNaN(prof)) {
            chart.series[serieIndex].addPoint({ x: fecha, y: dato, z: prof }, true, true);

            if (navigator && !isUserInteracting) {
                const extremes = chart.xAxis[0].getExtremes();
                const oldestDate = Math.min(...chart.series[serieIndex].xData);
                //console.log(extremes);

                if (extremes.min !== undefined) {
                    //chart.xAxis[0].setExtremes(null, fecha);//extremes.dataMin
                    //chart.xAxis[0].setExtremes(extremes?.dataMin, fecha);//
                    chart.xAxis[0].setExtremes(extremes.min, fecha);
                }
            }

        }
    };

    //------------------------------------------------------

    const updateSeriesAndAxis = (serieIndex, color) => {
        chart.series[serieIndex]?.update({ color: color }, false);
        chart.yAxis[serieIndex]?.update({
            color: color,
            lineColor: color,
            tickColor: color,
            title: { style: { color: color } },
            labels: { style: { color: color } }
        }, false);
    };

    //------------------------------------------------------

    const chartClickComments = (x, y, varIdn) => {
        if (!mobile) {
            setXComentario({ x, y, varIdn })
            setShowModalComments(true)
        }
    };

    //------------------------------------------------------

    const findAlarm = (ld) => {

        setVarAlarm(ld.vr_var_nom)
        setVarAlarmIdn(ld.nb_var_idn)

        if (ld.nb_alarm_min !== null) {
            setAlarmMin(ld.nb_alarm_min)
            setAlarmMax(ld.nb_alarm_max)
            setAlarmId(ld.nb_alarm_idn)
            setAlarmExist(1)
        } else {
            setAlarmMin(0)
            setAlarmMax(0)
            setAlarmExist(0)
        }
    }

    //------------------------------------------------------

    const actualizarEje = (ejeA, min1, max1, min2, max2) => {
        if (!ejeA) {
            chart?.yAxis[0]?.update({
                min: parseInt(min1),
                max: parseInt(max1)
            })
            setMin1(min1)
            setMax1(max1)
            chart?.yAxis[1]?.update({
                min: parseInt(min2),
                max: parseInt(max2)
            })
            setMin2(min2)
            setMax2(max2)
        } else {
            chart?.yAxis[0]?.update({
                min: 0,
                max: null
            });
            setMin1(0)
            setMax1(chart?.yAxis[0]?.max)
            chart?.yAxis[1]?.update({
                min: 0,
                max: null
            });
            setMin2(0)
            setMax2(chart?.yAxis[1]?.max)
        }
    }

    //------------------------------------------------------

    const grilla = (dato, colorSerie, position) => {
        let valor = dato?.nb_mda_val;

        if (position === 0) {
            const validar = parseFloat(nuevoDato2?.nb_mda_val, 10)
            if (!isNaN(validar)) valor = (parseFloat(nuevoDato1?.nb_mda_val, 10));
        } else {
            const validar = parseFloat(nuevoDato2?.nb_mda_val, 10)
            if (!isNaN(validar)) valor = (parseFloat(nuevoDato2?.nb_mda_val, 10));
        }

        const nbAlarmMax = parseFloat(dato?.nb_alarm_max)?.toFixed(2) || 0;
        const nbMdaVal = parseFloat(dato?.nb_mda_val)?.toFixed(2);
        const nbAlarmMin = parseFloat(dato?.nb_alarm_min)?.toFixed(2);

        return (
            chart && (
                <div className="card">
                    <div className="card-body text-center p-0 m-0 "
                        style={{ background: colors.grColorBg || '#ffffff', borderColor: colors.grColorBorder || '#000000' }}>
                        {dato && Object.keys(dato).length > 0 && !isNaN(valor) ?
                            <>
                                <div className="row text-center"
                                    style={{ color: colors.grColorFont || '#000000' }}>
                                    <div className="col-2 pe-0">
                                        {/* <ColorSelector
                                            colorSerie1={colorSerie1}
                                            setColorSerie1={setColorSerie1}
                                            colorSerie2={colorSerie2}
                                            setColorSerie2={setColorSerie2}
                                            position={position}
                                            sinColor={sinColor}
                                            rig={rig}
                                            usaDefault={usaDefault}
                                            uid={uid}
                                        /> */}
                                    </div>
                                    <div className="col-8">
                                        <h6
                                            className="card-title update-var m-0 "
                                            style={{
                                                fontSize: (mobile ? '12px' : 'auto'),
                                                backgroundImage: `linear-gradient(to right, #54b3d6, #54b3d6 50%, ${colorSerie} 50%)`
                                            }}
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="set Var"
                                            onClick={() => {
                                                if (usaDefault) {
                                                    toast.warning('No se puede modificar la variable, sin seleccionar una plantilla')
                                                } else {
                                                    setModalDownVar(dato)
                                                    setPosInferior(position + pos)
                                                    setShowModalVarDown(true)
                                                }
                                            }}>
                                            {dato.vr_var_nom}
                                        </h6>
                                    </div>
                                    <div className="col-2 ps-0">
                                        <span style={{ cursor: 'pointer' }}>
                                            <i className="fa fa-times fa-xs"
                                                style={{ color: colorSerie }}
                                                onClick={() => {
                                                    if (usaDefault) {
                                                        toast.warning('No se puede eliminar la variable, sin seleccionar una plantilla')
                                                    } else {
                                                        deleteVarMatriz(dato.nb_tldvrb_idn, position + pos)
                                                    }
                                                }}>
                                            </i></span>
                                    </div>
                                </div>
                                <h4 className={nbAlarmMax >= 0
                                    ? (nbMdaVal >= nbAlarmMin && nbMdaVal <= nbAlarmMax)
                                        ? "card-text m-0 alarm-var"
                                        : "card-text m-0 red-alarm"
                                    : "card-text alarm-var m-0"}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="set Alarm"
                                    onClick={() => {
                                        if (usaDefault) {
                                            toast.warning('seleccione una plantilla antes de modificar la alarma de la variable')
                                        } else {
                                            findAlarm(dato)
                                            setShowModalAlarm(true)
                                        }
                                    }}
                                    style={{ fontSize: mobile ? '15px' : 'auto' }}
                                >
                                    {dato.vr_var_umd === 'no/yes'
                                        ? valor === '0'
                                            ? 'NO' : 'YES'
                                        :
                                        parseFloat(parseFloat(valor) - parseInt(parseFloat(valor))).toFixed(2) > 0
                                            ? parseFloat(valor).toFixed(2)
                                            : parseFloat(valor).toFixed(0)
                                    }
                                </h4>
                                <div className="row">
                                    <div className="col-3 align-self-center">
                                        <p className="m-0"
                                            style={{ fontSize: mobile ? '8px' : "10px", color: colorSerie }}>
                                            {position === 0 ? min1 : min2} {/* dato.nb_tldvrb_min} */}
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <p className="m-0"
                                            style={{ fontSize: mobile ? '12px' : 'auto', color: colorSerie }}>
                                            {dato.vr_var_umd}
                                        </p>
                                    </div>
                                    <div className="col-3 align-self-center">
                                        <p className="m-0"
                                            style={{ fontSize: mobile ? '8px' : "10px", color: colorSerie }}>
                                            {position === 0 ? max1 : max2}  {/* dato.nb_tldvrb_max */}
                                        </p>
                                    </div>
                                </div>
                            </>
                            : <>
                                <h6 className="card-title"
                                    style={{ fontSize: mobile ? '12px' : 'auto' }}>
                                    Seleccionar
                                </h6>
                                <h3 className={`card-text ${mobile ? 'mb-0' : ''}`} >
                                    <button className={`btn btn-outline-primary fa-solid fa-plus ${mobile ? 'btn-sm' : ''}`}
                                        onClick={() => {
                                            setModalDownVar({})
                                            setPosInferior(position + pos)
                                            setShowModalVarDown(true)
                                        }} />
                                </h3>
                                <p></p>
                            </>
                        }
                    </div>
                </div>
            )
        )
    }

    const grillaTabla = (dato, colorSerie, position) => {
        let valor = dato?.nb_mda_val;

        if (position === 0) {
            const validar = parseFloat(nuevoDato2?.nb_mda_val, 10)
            if (!isNaN(validar)) valor = (parseFloat(nuevoDato1?.nb_mda_val, 10));
        } else {
            const validar = parseFloat(nuevoDato2?.nb_mda_val, 10)
            if (!isNaN(validar)) valor = (parseFloat(nuevoDato2?.nb_mda_val, 10));
        }

        const nbAlarmMax = parseFloat(dato?.nb_alarm_max)?.toFixed(2) || 0;
        const nbMdaVal = parseFloat(dato?.nb_mda_val)?.toFixed(2);
        const nbAlarmMin = parseFloat(dato?.nb_alarm_min)?.toFixed(2);

        return (
            chart && (
                <>
                    <table
                        className='table table-sm table-bordered mb-1'
                        style={{ borderColor: colors.grColorBorder, width: '95%' }}>
                        <tbody>
                            <tr className={`my-0 py-0 tabla-medida`}>
                                <td className='p-0' style={{ cursor: 'pointer', width: '25px' }}>
                                    {/* <ColorSelector
                                        colorSerie1={colorSerie1}
                                        setColorSerie1={setColorSerie1}
                                        colorSerie2={colorSerie2}
                                        setColorSerie2={setColorSerie2}
                                        position={position}
                                        sinColor={sinColor}
                                        rig={rig}
                                        usaDefault={usaDefault}
                                    /> */}
                                </td>

                                <td className="p-0"
                                    style={{ color: colorSerie }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="set Var"
                                    onClick={() => {
                                        if (usaDefault) {
                                            toast.warning('No se puede modificar la variable, sin seleccionar una plantilla')
                                        } else {
                                            setModalDownVar(dato)
                                            setPosInferior(position + pos)
                                            setShowModalVarDown(true)
                                        }
                                    }}>
                                    {dato?.vr_var_nom.length > 12 ? `${dato?.vr_var_nom.slice(0, 12)}...` : dato?.vr_var_nom}
                                </td>

                                <td style={{ cursor: 'pointer', width: '25px' }} className='p-0'>
                                    <i className="fa fa-times fa-xs ms-auto"
                                        style={{ color: colorSerie, fontSize: '7px' }}
                                        onClick={() => {
                                            if (usaDefault) toast.warning('No se puede eliminar la variable, sin seleccionar una plantilla')
                                            else deleteVarMatriz(dato?.nb_tldvrb_idn, position + pos)
                                        }}>
                                    </i>
                                </td>
                            </tr>

                            <tr className='my-0 py-0 tabla-medida'>
                                <td style={{ color: colorSerie }} className='p-0'> {dato?.nb_tldvrb_min} </td>
                                <td style={{ color: colorSerie }}
                                    className={' p-0 ' + (nbAlarmMax >= 0
                                        ? (nbMdaVal >= nbAlarmMin && nbMdaVal <= nbAlarmMax)
                                            ? "alarm-var-2"
                                            : "red-alarm-2"
                                        : "alarm-var-2 ") + 'tabla-medida'}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="set Alarm"
                                    onClick={() => {
                                        if (usaDefault) {
                                            toast.warning('seleccione una plantilla antes de modificar la alarma de la variable')
                                        } else {
                                            findAlarm(dato)
                                            setShowModalAlarm(true)
                                        }
                                    }}
                                >
                                    {`${dato?.vr_var_umd === 'no/yes'
                                        ? valor === '0'
                                            ? 'NO' : 'YES'
                                        :
                                        parseFloat(parseFloat(valor) - parseInt(parseFloat(valor))).toFixed(2) > 0
                                            ? parseFloat(valor).toFixed(2)
                                            : parseFloat(valor).toFixed(0)} ${dato?.vr_var_umd}`
                                    }

                                </td>
                                <td style={{ color: colorSerie }} className='p-0'>
                                    {dato?.nb_tldvrb_max}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </>
            )
        )
    }

    //------------------------------------------------------
    const moveToTheEnd = () => {
        if (!chart || !navigator) return;
        const extremes = chart.xAxis[0].getExtremes();
        chart.xAxis[0].setExtremes(extremes.dataMin, extremes.dataMax, true, { duration: 500 });
        setIsUserInteracting(false);
    };
    //------------------------------------------------------


    return (
        <div className={`p-0 col${navigator
            ? '-sm-12'
            : (showDataLeft && !showComments) || (!showDataLeft && showComments) ? '-sm-2' : ''}`}
        >

            <HighchartsReact
                highcharts={Highcharts}
                options={comments ? optionsFlags : options}
                constructorType={"stockChart"}
                ref={chartComponent}
            />
            {(!navigator && !comments && !horizontal) && (
                <>
                    {grilla(dato1, colorSerie1, 0)}
                    {grilla(dato2, colorSerie2, 1)}
                </>
            )}
            {(!navigator && !comments && horizontal) && (
                <>
                    {grillaTabla(dato1, colorSerie1, 0)}
                    {grillaTabla(dato2, colorSerie2, 1)}
                </>
            )}
            {(navigator && isUserInteracting) &&
                (
                    <button aria-label="toEnd"
                        className="btn btn-lg text-dark border-light fa-solid fa-arrow-down"
                        title='go to actual date'
                        onClick={moveToTheEnd}>
                    </button>
                )
            }
            <ModalComments
                show={showModalComments}
                handleClose={handleCloseModalComments}
                xComentario={xComentario}
                user={user}
                rig={rig}
            />

            <ModalAlarm
                rig={rig}
                varAlarm={varAlarm}
                alarmMin={alarmMin}
                alarmMax={alarmMax}
                alarmExist={alarmExist}
                varAlarmIdn={varAlarmIdn}
                alarmId={alarmId}
                setAlarmMin={setAlarmMin}
                setAlarmMax={setAlarmMax}
                functionN={funcionActualizarVarsDown}
                show={showModalAlarm}
                handleClose={handleCloseModalAlarm}
                uid={uid}
                user={user}
            />
        </div >
    )
}