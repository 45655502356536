import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import { LoaderES } from './LoaderES';
import ModalVarSelector from './ModalVarSelector';
import ModalAlarm from './ModalAlarm';
import moment from 'moment';
import ModalPlantilla from './ModalPlantilla';
import { deleteBd, getTableByIdBd, insertTableBd, updateTableBd } from '../config/functions';

const LiveData = ({ rig, mobile, variables, uid, user, colors, setComponente, componente }) => {

    const tiempoIntervalo = 5000
    const [liveDataM, setLiveDataM] = useState([])

    const [varAlarm, setVarAlarm] = useState(0)
    const [varAlarmIdn, setVarAlarmIdn] = useState('')
    const [alarmMin, setAlarmMin] = useState(0)
    const [alarmMax, setAlarmMax] = useState(0)
    const [alarmExist, setAlarmExist] = useState(0)
    const [alarmId, setAlarmId] = useState(0)

    const [actualizando, setActualizando] = useState("En espera de Datos")
    const nbRef = useRef('')
    const intervalRef = useRef(null);

    const [updateVar, setUpdateVar] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const [sinDatos, setSinDatos] = useState(false)

    const [tipoVista, setTipoVista] = useState(sessionStorage.getItem('tipoVista') || 'live')

    let part = 0

    //--------------------------------------------------------------

    const [showModalPlantilla, setShowModalPlantilla] = useState(false);
    const handleOpenModalPlantilla = () => setShowModalPlantilla(true);
    const handleCloseModalPlantilla = () => setShowModalPlantilla(false);

    const [showModalAlarm, setShowModalAlarm] = useState(false);
    const handleCloseModalAlarm = () => setShowModalAlarm(false);

    const [showModalVar, setShowModalVar] = useState(false);
    const handleCloseModalVar = () => setShowModalVar(false);

    //--------------------------------------------------------------

    useEffect(() => { return () => { if (intervalRef.current) clearInterval(intervalRef.current) } }, []);

    useEffect(() => {
        setActualizando('');
        if (intervalRef.current) clearInterval(intervalRef.current);
        if (!rig || rig === 0 || rig === '') return;
        renderLiveDataM();
        part = part + 1;
        intervalRef.current = setInterval(() => {
            renderLiveDataM(rig);
        }, tiempoIntervalo);
    }, [rig])

    useEffect(() => { sessionStorage.setItem('tipoVista', tipoVista) }, [tipoVista])

    //-----------------------------------------------------------

    const renderLiveDataM = async () => {
        const dataSend = `${rig}:${uid}`;
        let data = await getTableByIdBd(dataSend, 'getvarsalarmmatr');

        if (data.length === 0) {
            setSinDatos(true);
            const dataPlantilla = await getTableByIdBd(rig, 'getlivedataplantilla');
            data = dataPlantilla;

            if (dataPlantilla.length === 0) {
                setActualizando("Sin Datos");
                setLiveDataM([]);
                return;
            }
        } else {
            setSinDatos(false);
        }

        let valorArr = data.find(r => r.dt_mda_fch !== null);

        if (!valorArr?.dt_mda_fch) {
            setActualizando("Sin Datos transmitidos");
            setLiveDataM(data);
            return;
        }

        const fecha = moment.utc(valorArr.dt_mda_fch).format('MMMM Do YYYY, h:mm:ss a');
        const nuevaFecha = valorArr.dt_mda_fch;

        data.sort((a, b) => a.nb_matr_colum - b.nb_matr_colum);
        setLiveDataM(data);

        //console.log(nuevaFecha);
        if (nbRef.current !== nuevaFecha) {
            setActualizando("Actualizado: " + fecha);
            nbRef.current = nuevaFecha;  // Actualizamos la referencia solo si la fecha cambia
        } else {
            setActualizando("Sin actualización desde: " + fecha);
        }

    };

    //-----------------------------------------------------------

    const findAlarm = (ld) => {
        setVarAlarm(ld.vr_var_nom);
        setVarAlarmIdn(ld.nb_var_idn);
        setAlarmMin(ld.nb_alarm_min !== null ? ld.nb_alarm_min : 0);
        setAlarmMax(ld.nb_alarm_min !== null ? ld.nb_alarm_max : 0);
        setAlarmId(ld.nb_alarm_min !== null ? ld.nb_alarm_idn : null);
        setAlarmExist(ld.nb_alarm_min !== null ? 1 : 0);
    };

    //-------------------------------------------------------------

    /* adicionar variable a la matriz guardada */
    const addVarMatriz = async (ld) => {
        const fecha = new Date()
        const dataSend = {
            nb_tldro_idn: parseInt(rig),
            nb_var_idn: parseInt(ld.nb_var_idn),
            nb_usurio_idn: uid,
            nb_matr_fila: 0,
            nb_matr_colum: (liveDataM.length),
            vr_adt_usureg: 'admin',
            dt_adt_fchreg: fecha.toISOString()
        }
        try {
            await insertTableBd('createvarmatriz', dataSend)
            renderLiveDataM()
            setShowModalVar(false)
            toast.success('Variable insertada de manera correcta');
        } catch (error) {
            console.error(error);
        }
    }

    /* actualizar variable a la matriz guardada */
    const updateVarMatriz = async (ld) => {
        const fecha = new Date()
        const dataSend = {
            nb_matr_idn: parseInt(updateVar),
            nb_var_idn: parseInt(ld.nb_var_idn),
            vr_adt_usureg: user,
            dt_adt_fchreg: fecha.toISOString()
        }
        try {
            await updateTableBd('updatevarmatriz', dataSend)
            toast.success('Variable Actualizada de manera correcta');
            setShowModalVar(false)
            renderLiveDataM()
        } catch (error) {
            console.error(error);
        }
    }

    /* eliminar última variable de la matriz*/
    const deleteVarMatriz = async (matr_idn) => {
        if (window.confirm('Esta seguro de continuar?')) {
            try {
                await deleteBd(matr_idn, 'deleteVarMatriz')
                toast.success('Se elimino la variable de forma correcta');
                renderLiveDataM()
            } catch (error) {
                console.error(error);
            }
        }
    }

    //-------------------------------------------------------------

    //-------------------------------------------------------------

    const varPrincipal = (ld) => {
        return (

            <div className="card pe-0 "
                style={{ background: colors.ldColorBg || '#ffffff', borderColor: colors.ldColorBorder || '#000000' }}>
                <div className="card-body text-center p-1" style={{ color: colors.ldColorFont || '#000000' }}>

                    <h3 className="card-title update-var mb-0"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="set Var"
                        onClick={() => {
                            if (sinDatos) {
                                toast.error('No se ha asignado una plantilla para poder editar las variables')
                                return
                            }
                            setUpdateVar(ld.nb_matr_idn)
                            setShowModalVar(true)
                        }}>
                        {ld.vr_var_nom}
                    </h3>



                    {parseInt(ld.nb_matr_colum) === parseInt(liveDataM.length) - 1 ?
                        <span className=" text-end me-1"
                            onClick={() => deleteVarMatriz(ld.nb_matr_idn)}>
                            &nbsp;&nbsp;&nbsp;&nbsp; <i className="fa fa-times pe-auto"></i>
                        </span>
                        : null
                    }


                    {/* valor de la variable */}
                    <h2 className={(parseInt(ld.nb_alarm_max) >= 0
                        ? parseInt(ld.nb_mda_val) >= parseInt(ld.nb_alarm_min) && parseInt(ld.nb_mda_val) <= parseInt(ld.nb_alarm_max)
                            ? "card-text mb-0 alarm-var"
                            : "card-text mb-0  red-alarm"
                        : "card-text alarm-var mb-0")}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="set Alarm"
                        onClick={() => {
                            if (sinDatos) {
                                toast.error('No se ha asignado una plantilla para poder editar las variables')
                                return
                            }
                            findAlarm(ld)
                            setShowModalAlarm(true)
                        }}>
                        {ld.vr_var_umd === 'no/yes'
                            ? ld.nb_mda_val === '0'
                                ? 'NO' : 'YES'
                            :
                            parseFloat(parseFloat(ld.nb_mda_val) - parseInt(parseFloat(ld.nb_mda_val))).toFixed(2) > 0
                                ? parseFloat(ld.nb_mda_val).toFixed(2)
                                : parseFloat(ld.nb_mda_val).toFixed(0)
                        }
                    </h2>
                    {/* valor de la variable */}

                    {/* Unidad de Medida */}
                    <p className='mb-1'>{ld.vr_var_umd}</p>
                    {/* Unidad de Medida */}
                    <p></p>
                </div>
            </div>

        )
    }

    const varSecundaria = (ld) => {
        return (
            <div className="card pe-0 "
                style={{ background: colors.ldColorBg || '#ffffff', borderColor: colors.ldColorBorder || '#000000' }}>
                <div className="card-body text-center p-1" style={{ color: colors.ldColorFont || '#000000' }}>
                    {/* Nombre de variable */}
                    <h6 className="card-title update-var mb-0"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="set Var"
                        onClick={() => {
                            if (sinDatos) {
                                toast.error('No se ha asignado una plantilla para poder editar las variables')
                                return
                            }
                            setUpdateVar(ld.nb_matr_idn)
                            setShowModalVar(true)
                        }}>
                        {ld.vr_var_nom}
                    </h6>
                    {/* Nombre de variable */}

                    {/* eliminar variable */}
                    {parseInt(ld.nb_matr_colum) === parseInt(liveDataM.length) - 1 ?
                        <span className=" text-end me-1"
                            onClick={() => deleteVarMatriz(ld.nb_matr_idn)}>
                            &nbsp;&nbsp;&nbsp;&nbsp; <i className="fa fa-times pe-auto"></i>
                        </span>
                        : null
                    }
                    {/* eliminar variable */}

                    {/* valor de la variable */}
                    <h3 className={(parseInt(ld.nb_alarm_max) >= 0
                        ? parseInt(ld.nb_mda_val) >= parseInt(ld.nb_alarm_min) && parseInt(ld.nb_mda_val) <= parseInt(ld.nb_alarm_max)
                            ? "card-text mb-0 alarm-var"
                            : "card-text mb-0  red-alarm"
                        : "card-text alarm-var mb-0")}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="set Alarm"
                        onClick={() => {
                            if (sinDatos) {
                                toast.error('No se ha asignado una plantilla para poder editar las variables')
                                return
                            }
                            findAlarm(ld)
                            setShowModalAlarm(true)
                        }}>
                        {ld.vr_var_umd === 'no/yes'
                            ? ld.nb_mda_val === '0'
                                ? 'NO' : 'YES'
                            :
                            parseFloat(parseFloat(ld.nb_mda_val) - parseInt(parseFloat(ld.nb_mda_val))).toFixed(2) > 0
                                ? parseFloat(ld.nb_mda_val).toFixed(2)
                                : parseFloat(ld.nb_mda_val).toFixed(0)
                        }
                    </h3>
                    {/* valor de la variable */}

                    {/* Unidad de Medida */}
                    <p className='mb-1'>{ld.vr_var_umd}</p>
                    {/* Unidad de Medida */}
                    <p></p>
                </div>
            </div>
        )
    }

    const agregarVariable = () => {
        return (
            <div className="col-sm-2">
                <div className="card pe-0"
                    style={{ background: colors.ldColorBg || '#ffffff', borderColor: colors.ldColorBorder || '#000000' }}>
                    <div className="card-body text-center p-1">
                        <h6 className="card-title" style={{ color: colors.ldColorFont || '#000000' }}>Seleccionar</h6>
                        <h3 className="card-text">
                            <button className='btn update-var'
                                style={{ color: colors.ldColorFont || '#000000' }}
                                onClick={() => {
                                    setUpdateVar(0)
                                    setShowModalVar(true)
                                }} >
                                <i className="fa-solid fa-plus"></i>
                            </button>
                        </h3>
                        <p></p>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        document.documentElement.style.setProperty('--color-default', colors.ldColorFont);
        document.documentElement.style.setProperty('--color-measure', colors.ldColorMeasure);
        document.documentElement.style.setProperty('--color-alarm', colors.ldColorAlarm);
    }, [colors]);
    //---------------------------------------------------------------

    return (
        <>
            <div className="row mt-3 mb-3 justify-content-center">
                {/* Validar datos para dar parte de actualizado y selector de plantilla */}
                {rig !== 0 ?
                    <>
                        <div className="col col-auto mb-2 text-center" style={{ color: colors.colorFont || '#000000' }}>
                            {actualizando}
                        </div>
                        <div className="col col-auto mb-2">
                            <i className="fa-regular fa-object-group plantilla"
                                onClick={handleOpenModalPlantilla}></i>
                        </div>
                        <div className="col col-auto mb-2">
                            {actualizando.split(':', 2)[0] === 'Actualizado' ?
                                <i className="fa-solid fa-circle" style={{ color: "green" }}></i>
                                : <i className="fa-solid fa-circle" style={{ color: "red" }}></i>
                            }
                        </div>
                        {sinDatos && (
                            <div className="col col-auto mb2">
                                <i className="fa-solid fa-exclamation-triangle btn btn-sm" style={{ color: "orange" }}
                                    onClick={() => toast.warning('Recuerde asignar una plantilla para poder modificar el orden y las variables a gusto personal')} />
                            </div>
                        )}
                        <div className="col col-auto mb2">
                            <button
                                className={`btn btn-sm btn-outline-info fa fa-${tipoVista === 'live' ? 'table' : 'braille'} border-0 `}
                                title={tipoVista === 'live' ? 'Large View' : 'Minimal View'}
                                onClick={() => setTipoVista(tipoVista === 'live' ? 'large' : 'live')} />
                        </div>
                    </>
                    : liveDataM.length > 0
                        ? <div className="col col-auto mb-2">
                            <i className="fa-regular fa-object-group plantilla"
                                onClick={handleOpenModalPlantilla}></i>
                        </div>
                        : <h5>Seleccione un Rig para visualizar</h5>
                }
            </div>

            <div className="row">
                {isLoading
                    ? <LoaderES />
                    : <>
                        {liveDataM && liveDataM.map((ld, index) => (
                            index < (tipoVista === 'live' ? 0 : 8) ?
                                <div className=" col-12 col-sm-6 col-md-3 col-xl-3 mb-2"
                                    key={sinDatos ? ld.nb_tldvrb_ord : ld.nb_matr_colum + ld.nb_matr_idn}>
                                    {varPrincipal(ld)}
                                </div>
                                : <div className=" col-6 col-sm-4 col-md-2 col-xl-2 mb-2"
                                    key={sinDatos ? ld.nb_tldvrb_ord : ld.nb_matr_colum + ld.nb_matr_idn}>
                                    {varSecundaria(ld)}
                                </div>
                        ))}
                        {!sinDatos && (agregarVariable())}
                    </>
                }
            </div>

            <ModalVarSelector
                variables={variables}
                updateVar={updateVar}
                addVarMatriz={addVarMatriz}
                updateVarMatriz={updateVarMatriz}
                show={showModalVar}
                handleClose={handleCloseModalVar}
            />

            <ModalAlarm
                rig={rig}
                varAlarm={varAlarm}
                alarmMin={alarmMin}
                alarmMax={alarmMax}
                alarmExist={alarmExist}
                varAlarmIdn={varAlarmIdn}
                alarmId={alarmId}
                setAlarmMin={setAlarmMin}
                setAlarmMax={setAlarmMax}
                functionN={renderLiveDataM}
                show={showModalAlarm}
                handleClose={handleCloseModalAlarm}
                uid={uid}
                user={user}
            />

            <ModalPlantilla
                rig={rig}
                liveDataM={liveDataM}
                setIsLoading={setIsLoading}
                functionN={renderLiveDataM}
                tipo={'live'} //large
                show={showModalPlantilla}
                handleClose={handleCloseModalPlantilla}
                uid={uid}
                user={user}
            />
        </>
    )
}

export default LiveData