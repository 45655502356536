import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { LoaderES } from '../../LoaderES';
import { Filtrar } from '../../Filtrar';
import { Pagination } from '../../Pagination';
import { ModalUserEdit } from './ModalUserEdit';
import { ModalUserPerm } from './ModalUserPerm';
import { Collapse } from 'react-bootstrap';
import { getTableBd, getTableByIdBd, ordenar, updateTableBd } from '../../../config/functions';
import moment from 'moment';

const Users = ({ broken, collapsed, mobile, user, colors }) => {

  const [usuarioAll, setUsuarioAll] = useState({})

  const [usuarios, setUsuarios] = useState([])
  const [usuariosInactivos, setUsuariosInactivos] = useState([])
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [taladros, setTaladros] = useState([])
  const [taladrosUsuario, setTaladrosUsuario] = useState([])

  const [cambio, setCambio] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(null);

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([])

  const [modal, setModal] = useState('')
  const [showModalPerm, setShowModalPerm] = useState(false);
  const handleCloseModalPerm = () => setShowModalPerm(false);

  const [showModalEdit, setShowModalEdit] = useState(false);
  const handleCloseModalEdit = () => setShowModalEdit(false);

  const [mostrarInactivos, setMostrarInactivos] = useState(false);

  //--------------------------------------------

  const getTldrs = async () => {
    setIsLoading(true)
    try {
      const tl = await getTableBd('gettldrs')
      setTaladros(tl);
    } catch (error) {
      toast.warning('bd don\'t work');
    } finally {
      setIsLoading(false)
    }
  }

  const getUsers = async () => {
    setIsLoading(true)
    try {
      const data = await getTableBd('getusers2')
      const lastLogin = await getTableBd('getuserslogin')
      data.forEach(d => {
        const matchingData = lastLogin.find(l => l.vr_usurio_usu === d.vr_usurio_usu);
        d.last_login_date = matchingData ? matchingData.last_login_date : ''
      });
      const dataA = data.filter(u => u.vr_usurio_est !== 'i');
      const dataI = data.filter(u => u.vr_usurio_est === 'i');
      setDocumentosFiltrados(dataA)
      setUsuarios(dataA);
      setUsuariosInactivos(dataI);
      await getTldrs();
    } catch (error) {
      toast.warning('bd don\'t work');
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => { getUsers() }, [])

  //---------------------------------------------

  const changePass = async (idu) => {
    if (cambio === '1') {
      toast.warning('Ya existe una solicitud de cambio de contraseña')
    } else {
      if (window.confirm('En el próximo inicio de sesión el usuario debera cambiar su contraseña')) {
        try {
          setIsLoading(true)
          const dataSend = {
            nb_usurio_idn: idu,
            nb_usurio_cmbcla: '1',
          }
          const data = await updateTableBd('updateuserstate2', dataSend)
          toast.success(data);
          getUsers();
        } catch (error) {
          toast.warning('bd don\'t work');
        } finally {
          setIsLoading(false)
        }

      }
    }
  }

  //---------------------------------------------

  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
  }, [documentosFiltrados, registrosPorPagina])

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
  }, [paginaActual, totalPaginas])

  //---------------------------------------------

  const thCW = (n, o) => {
    return (
      <th scope="col" >{n} &nbsp;&nbsp;
        <i className="fa-solid fa-arrow-down-a-z"
          onClick={() => ordenar(o)}
          style={{ cursor: "pointer" }}>
        </i></th>
    )
  }

  const tdMob = (title, valor) => {
    return (
      <tr>
        <td>{title}: </td>
        <td>{valor}</td>
      </tr>
    )
  }

  //---------------------------------------------

  const getRigs = async (u) => {
    setUsuarioAll(u)
    setIsLoading(true);
    try {
      const data = await getTableByIdBd(u.nb_usurio_idn, 'getrigsusers');
      const taladrosUsuarioN = taladros.map(taladro => {
        const matchingData = data.find(d => parseInt(d.nb_tldro_idn) === parseInt(taladro.nb_tldro_idn));
        return { ...taladro, check: matchingData ? 1 : 0 }
      });
      setTaladrosUsuario(taladrosUsuarioN);
      setShowModalPerm(true)
    } catch (error) {
      toast.warning('bd don\'t work');
    } finally {
      setIsLoading(false)
    }
  }

  //---------------------------------------------

  const handleToggleUsuarios = () => {
    if (mostrarInactivos) {
      setDocumentosFiltrados(usuarios);
      setRegistrosPaginaActual(usuarios);
    } else {
      setDocumentosFiltrados(usuariosInactivos);
      setRegistrosPaginaActual(usuariosInactivos);
    }
    setMostrarInactivos(!mostrarInactivos);
  };

  //---------------------------------------------

  return (
    usuarios.length === 0 || isLoading
      ? <LoaderES />
      : <>

        <div className={`row ${mobile ? 'mb-3' : ''}`}>
          
          <div className="col-4 col-md-1">
            <div className="btn-group">
              <button className='btn fa-solid fa-user-plus'
                style={{ color: colors.colorFont || '#000000', borderColor: colors.colorFont || '#000000' }}
                title='Nuevo Usuario'
                onClick={() => {
                  setUsuarioAll({})
                  setModal('Nuevo')
                  setShowModalEdit(true)
                }} />
              <button
                title={`Mostrar usuarios ${mostrarInactivos ? 'activos' : 'inactivos'}`}
                className={`btn fa-solid ${mostrarInactivos ? 'fa-eye' : 'fa-eye-slash'}`}
                style={{ color: colors.colorFont || '#000000', borderColor: colors.colorFont || '#000000' }}
                onClick={() => handleToggleUsuarios()}
              />
            </div>
          </div>

          <div className="col-8 col-md-11">
            <Filtrar
              documentos={usuarios}
              setDocumentosFiltrados={setDocumentosFiltrados}
              setRegistrosFiltrados={setRegistrosPaginaActual}
              registrosPorPagina={registrosPorPagina}
              colors={colors} />
          </div>
        </div>

        {mobile
          ? <>
            {registrosPaginaActual && registrosPaginaActual.map((user, index) => (
              user.nb_usurio_idn !== '1' &&
              <div key={user.nb_usurio_idn}>
                <table className="table table-sm table-hover table-striped mb-0" style={{ color: colors.colorFont || '#000000' }}>
                  <thead style={{ fontSize: '14px' }}>
                    <tr>
                      <th style={{ width: '50px', resize: 'none' }}>
                        <button className="btn  fa-solid fa-chevron-down btn-sm"
                          style={{ color: colors.colorFont || '#000000' }}
                          onClick={() => setOpen(open === index ? null : index)}
                          aria-controls={"collapse-user-" + index}
                          aria-expanded={open === index}
                        />
                      </th>
                      <th style={{ width: '150px', resize: 'none' }}>{user.vr_usurio_usu}
                        <span style={{ fontSize: '8px' }}>
                          {` (${user.last_login_date === '' ? '' : moment(user.last_login_date).format('DD-MM-YYYY h:mm a')})`}
                        </span>
                      </th>
                      <th style={{ width: '50px', resize: 'none' }} className='text-end'>
                        <div className="btn-group">

                          <button className='btn btn-sm fa-solid fa-pencil'
                            style={{ color: colors.colorFont || '#000000' }}
                            onClick={() => {
                              setUsuarioAll(user)
                              setModal('')
                              setShowModalEdit(true)
                            }}
                          >
                          </button>

                          <button className='btn btn-sm fa-solid fa-key'
                            style={{ color: colors.colorFont || '#000000' }}
                            onClick={() => {
                              setCambio(user.nb_usurio_cmbcla)
                              changePass(user.nb_usurio_idn)
                            }}>
                          </button>

                          <button className='btn btn-sm fa-solid fa-list-check'
                            style={{ color: colors.colorFont || '#000000' }}
                            onClick={() => getRigs(user)}>
                          </button>

                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>

                <Collapse in={open === index}>
                  <div id={"collapse-user-" + index} className="mt-2">
                    <div className="card card-body" style={{ backgroundColor: colors.colorBg || '#000000', borderColor: colors.colorFont || '#000000' }}>
                      <table className="table table-sm" style={{ color: colors.colorFont || '#000000' }}>
                        <tbody style={{ fontSize: '12px' }}>
                          {tdMob('TAG', `${user.nb_usurio_idn}${user.nb_usurio_cmbcla === '1' ? '*' : ''}`)}
                          {tdMob('usuario', user.vr_usurio_usu)}
                          {tdMob('Tipo', user.nb_rol_idn === '1' ? 'Admin' : 'User')}
                          {tdMob('Nombre 1', user.vr_usurio_nom1)}
                          {tdMob('Nombre 2', user.vr_usurio_nom2)}
                          {tdMob('Apellido 1', user.vr_usurio_ape1)}
                          {tdMob('Apellido 2', user.vr_usurio_ape2)}
                          {tdMob('Tel', user.vr_usurio_telefn)}
                          {tdMob('Correo', user.vr_usurio_cor)}
                          {tdMob('Estado', user.vr_usurio_est === 'a' ? 'Activo' : 'Inactivo')}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Collapse>
              </div >
            ))}
          </>

          : <div className="table-responsive mt-3" >
            <table className="table table-hover table-sm align-middle table-bordered" id='myTable'
              style={{ color: colors.colorFont || '#000000' }}>
              <thead>
                <tr>
                  <th scope="col"></th>
                  {thCW('TAG', 1)}
                  {thCW('usuario', 2)}
                  {thCW('Tipo', 3)}
                  {thCW('Nombre 1', 4)}
                  {thCW('Nombre 2', 5)}
                  {thCW('Apellido 1', 6)}
                  {thCW('Apellido 2', 7)}
                  {thCW('Tel', 8)}
                  {thCW('Correo', 9)}
                  {thCW('Estado', 10)}
                  {thCW('Last Login', 11)}
                </tr>
              </thead>
              <tbody>
                {usuarios && usuarios.length > 0
                  ? registrosPaginaActual.map(usr => (
                    usr.nb_usurio_idn !== '1' &&
                    <tr key={usr.nb_usurio_idn} style={{ fontSize: '11px' }}
                      className={usr.nb_usurio_cmbcla === '1' || usr.vr_usurio_est === 'i' ? (usr.vr_usurio_est === 'a' ? 'table-warning' : 'table-danger') : ''}>
                      <td>
                        <div className="btn-group">
                          <button className='btn btn-sm fa-solid fa-pencil'
                            style={{ color: colors.colorFont || '#000000' }}
                            onClick={() => {
                              setModal('')
                              setUsuarioAll(usr)
                              setShowModalEdit(true)
                            }}
                          />
                          <button className='btn btn-sm fa-solid fa-key'
                            style={{ color: colors.colorFont || '#000000' }}
                            onClick={() => {
                              setCambio(usr.nb_usurio_cmbcla)
                              changePass(usr.nb_usurio_idn)
                            }} />
                          <button className='btn btn-sm fa-solid fa-list-check'
                            style={{ color: colors.colorFont || '#000000' }}
                            onClick={() => getRigs(usr)} />
                        </div>
                      </td>

                      <td>{usr.nb_usurio_idn}{usr.nb_usurio_cmbcla === '1' ? '*' : ''}</td>
                      <td>{usr.vr_usurio_usu}</td>
                      <td scope="row">{usr.nb_rol_idn === '1' ? 'Admin' : 'User'}</td>
                      <td>{usr.vr_usurio_nom1}</td>
                      <td>{usr.vr_usurio_nom2}</td>
                      <td>{usr.vr_usurio_ape1}</td>
                      <td>{usr.vr_usurio_ape2}</td>
                      <td>{usr.vr_usurio_telefn}</td>
                      <td>{usr.vr_usurio_cor}</td>
                      <td>{usr.vr_usurio_est === 'a' ? 'Activo' : 'Inactivo'}</td>
                      <td>{usr.last_login_date === '' ? '' : moment(usr.last_login_date).format('DD MMMM YYYY, h:mm:ss a')}</td>
                    </tr>
                  ))
                  : null
                }
              </tbody>
            </table>
          </div>
        }

        <Pagination
          paginaActual={paginaActual}
          totalPaginas={totalPaginas}
          setPaginaActual={setPaginaActual}
          registrosPorPagina={registrosPorPagina}
          setRegistrosPorPagina={setRegistrosPorPagina}
          documentosFiltrados={documentosFiltrados}
          broken={broken}
          collapsed={collapsed}
          mobile={mobile}
          colors={colors}
        />

        <ModalUserEdit
          usuarios={usuarios}
          usuarioAll={usuarioAll}
          setIsLoading={setIsLoading}
          getUsers={getUsers}
          show={showModalEdit}
          handleClose={handleCloseModalEdit}
          modal={modal}
          user={user}
        />

        <ModalUserPerm
          usuarioAll={usuarioAll}
          setIsLoading={setIsLoading}
          show={showModalPerm}
          handleClose={handleCloseModalPerm}
          taladrosUsuario={taladrosUsuario}
          user={user}
        />

      </>
  )
}

export default Users
